const i18n = {
  'en-US': {
    'login.form.title': 'Login to 鑫诚国际',
    'login.form.userName.errMsg': 'Username cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.userName.placeholder': 'Username',
    'login.form.password.placeholder': 'Password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'login',
    'login.form.register': 'register account',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1': 'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2': 'Internationalization, routing configuration, state management everything',
    'login.banner.slogan3': 'Access visualization enhancement tool AUX',
    'login.banner.subSlogan3': 'Realize flexible block development'
  },
  'zh-CN': {
    'login.form.title': '登录 鑫诚国际',
    'login.form.userName.errMsg': '用户名不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.userName.placeholder': '用户名',
    'login.form.password.placeholder': '密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan1': '鑫诚国际跨境支付',
    'login.banner.subSlogan1': '专业为商家打造的一站式跨境收付服务',
    'login.banner.slogan2': '全球资源优势与布局',
    'login.banner.subSlogan2': '引领数字支付行业',
    'login.banner.slogan3': '开放性合作与创新',
    'login.banner.subSlogan3': '快捷、灵活、安全的跨境支付商务',
    "login.google": "谷歌验证码"
  }
};
export default i18n;