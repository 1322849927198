const i18n = {
  'zh-CN': {
    'new': "新增",
    "edit": "编辑",
    "delete": "删除",
    "upload": "上传",
    "submit": "提交",
    "reset": "重置",
    'send': "发送",
    "bind": "绑定",
    "unset": "未设置",
    "bind.success": "绑定成功",
    "error": "失败",
    "success": "成功",
    "is_default": "当前",
    "copy": "一键复制信息",
    "copy.success": "复制成功",
    'status': '状态',
    'placeholder.default': "全部",
    "field": "字段",
    "field.add": "新增字段",
    "field.required": "必填",
    "field.option": "可选",
    "field.text": "文本",
    "field.textarea": "多行文本",
    "field.number": "数字",
    "field.image": "图片",
    "field.name.placeholder": "请填写字段名",
    "field.required.error": "字段为必填项",
    "pay_fees": "代付费率",
    "receive_fees": "代收费率",
    "extra_fees": "单笔收费",
    "extra_fees.placeholder": "单笔收费",
    "percentage": "百分比",
    "fixed": "固定值",
    "fees_placeholder": "请填写费率",
    "permissions.GET /api/admin/google/secret": "谷歌验证器",
    "permissions.GET /api/flows": "资金流水",
    "permissions.POST /api/withdraw": "申请提现",
    "permissions.GET /api/withdraw": "提现记录",
    "permissions.GET /api/dashboard/financial": "财务统计",
    "permissions.GET /api/dashboard": "控制台",
    "permissions.GET /api/transaction": "查看订单",
    "permissions.GET /api/app": "应用管理",
    "permissions.POST /api/admin/google/bind": "绑定谷歌验证器",
    "permissions.DELETE /api/admin/google/unbind/:id": "解除谷歌验证器绑定",
    "permissions.GET /api/logs": "操作日志",
    "permissions.PUT /api/subject/:id": "修改商家",
    "permissions.PUT /api/admin/password/modify": "修改账号密码",
    "permissions.PUT /api/withdraw/extra/exchange_rate": "更新默认汇率",
    "permissions.PUT /api/withdraw/:id": "审核提现",
    "permissions.DELETE /api/account/:id": "删除账号",
    "permissions.PUT /api/account/:id": "更新账号",
    "permissions.POST /api/account": "创建账号",
    "permissions.GET /api/account": "账号列表",
    "permissions.PUT /api/transaction/:id": "修改订单",
    "permissions.POST /api/subject": "创建商家",
    "permissions.GET /api/subject": "商家列表",
    "permissions.POST /api/app": "新增应用",
    "permissions.PUT /api/app/:id": "修改应用",
    "permissions.GET /api/role": "角色列表",
    "permissions.GET /api/admin": "账号列表",
    "permissions.PUT /api/admin/:id": "修改账号",
    "permissions.POST /api/admin": "创建账号",
    "permissions.GET /api/country": "国家列表",
    "permissions.POST /api/country": "创建国家",
    "permissions.PUT /api/country/:id": "更新国家",
    "permissions.POST /api/role": "创建角色",
    "permissions.PUT /api/role": "编辑角色",
    "permissions.GET /api/channel": "渠道列表",
    "permissions.POST /api/channel": "创建渠道",
    "permissions.PUT /api/channel/:id": "更新渠道",
    "permissions.GET /api/withdraw_channel": "提现渠道列表",
    "permissions.POST /api/withdraw_channel": "创建提现渠道",
    "permissions.PUT /api/withdraw_channel/:id": "更新提现渠道",
    'menu.dashboard': '仪表盘',
    'menu.list': '列表页',
    'menu.result': '结果页',
    'menu.exception': '异常页',
    'menu.form': '表单页',
    'menu.profile': '详情页',
    'menu.visualization': '数据可视化',
    'menu.user': '个人中心',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.profile.basic': '基础详情页',
    'menu.list.cardList': '卡片列表',
    'menu.visualization.dataAnalysis': '分析页',
    'menu.result.error': '失败页',
    'menu.form.group': '分组表单',
    'menu.dashboard.monitor': '实时监控',
    'menu.visualization.multiDimensionDataAnalysis': '多维数据分析',
    'menu.list.searchTable': '查询表格',
    'menu.form.step': '分步表单',
    'menu.result.success': '成功页',
    'menu.user.info': '用户信息',
    'menu.user.password': '修改密码',
    'menu.user.password.success': '修改密码成功，请重新登录',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'menu.dashboard.workplace': '工作台',
    'navbar.logout': '退出登录',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
    'navbar.search.placeholder': '输入内容查询',
    'dashboard.welcomeBack': "欢迎回来，",
    'dashboard.today.receive_orders': "今日代收订单量",
    'dashboard.today.receive_amouts': "今日代收金额",
    'dashboard.today.pay_orders': "今日代付订单量",
    'dashboard.today.pay_amouts': "今日代付金额",
    'dashboard.overview.content': "收入统计",
    'dashboard.overview.recent': "近7天",
    'dashboard.overview.receive_amount': "代收金额",
    'dashboard.overview.pay_amount': "代付金额",
    'dashboard.announcement': "公告",
    'dashboard.docs': "文档中心",
    'account.management': "账号管理",
    'account.admin': "账号列表",
    'account.admin.name': "名称",
    'account.admin.username': "用户名",
    'account.admin.password': "密码",
    'account.admin.password.extra': "留空则为不修改密码",
    'account.admin.subject': "商家",
    'account.admin.remark': "备注",
    'account.admin.role': "角色",
    'account.admin.last_login_at': "上次登录时间",
    'account.admin.created_at': "创建时间",
    'account.admin.name.placeholder': "请填写名称",
    'account.admin.username.placeholder': "请填写用户名",
    'account.admin.password.placeholder': "请填写密码",
    'account.admin.subject.placeholder': "请选择商家",
    'account.admin.role.placeholder': "请选择角色",
    'account.admin.remark.placeholder': "请填写备注",
    'account.role': "角色列表",
    'account.role.name': "名称",
    "account.role.permissions": "权限",
    "account.role.new": "新增角色",
    "account.role.edit": "编辑角色",
    'account.role.name.placeholder': "请填写角色名称",
    'account.role.permissions.placeholder': "请选择角色权限",
    "merchant.management": "商家管理",
    "merchant.list": "商家列表",
    "merchant.id": "商家ID",
    "merchant.name": "商家名",
    "merchant.balance": "余额",
    "merchant.freeze": "冻结金额",
    "merchant.withdraw": "提现汇率",
    "merchant.withdraw.channel": "提现方式",
    "merchant.withdraw.channel.placeholder": "请选择提现方式",
    "merchant.withdraw.edit": "调整提现汇率",
    "merchant.withdraw.placeholder": "请填写汇率",
    "merchant.fees_info.pay": "代付费率信息",
    "merchant.fees_info.receive": "代收费率信息",
    "merchant.fees_info.pay_extra": "代付单笔费用",
    "merchant.name.placeholder": "请填写商家名称",
    "merchant.channel": "产品设置",
    "merchant.channel.name": "产品",
    "merchant.channel.not": "尚未接入",
    "merchant.channel.widget": "权重",
    "merchant.apps": "应用管理",
    "merchant.apps.id": "应用ID",
    "merchant.apps.name": "应用名称",
    "merchant.apps.name.placeholder": "请填写应用名称",
    "merchant.apps.subject": "商家",
    "merchant.apps.subject.placeholder": "请选择商家",
    "merchant.apps.country": "国家",
    "merchant.apps.country.placeholder": "请选择国家",
    "merchant.apps.key": "App Key",
    "merchant.apps.receive_amount": "入款总金额(今日)",
    "merchant.apps.receive_fees": "入款手续费(今日)",
    "merchant.apps.pay_amount": "代付总金额(今日)",
    "merchant.apps.pay_fees": "代付手续费(今日)",
    "merchant.apps.secret": "App Secret",
    "merchant.apps.whitelist": "IP 白名单",
    "merchant.apps.edit": "修改IP白名单",
    "merchant.apps.add": "增加",
    "system.management": "系统管理",
    "system.country": "国家管理",
    "system.country.id": "国家地区代码",
    "system.country.name": "国家名称",
    "system.country.currency": "货币",
    "system.country.symbol": "货币符号",
    "system.country.exchange_rate": "汇率",
    "system.country.is_default": "设为默认",
    "system.country.id.placeholder": "请填写国家地区代码",
    "system.country.name.placeholder": "请填写国家名称",
    "system.country.currency.placeholder": "请填写国家货币",
    "system.country.symbol.placeholder": "请添加国家货币符号",
    "system.country.id.link": "查询国家地区代码",
    "system.channel": "渠道管理",
    "system.channel.id": "渠道ID",
    "system.channel.name": "渠道名称",
    "system.channel.logo": "渠道Logo",
    "system.channel.support": "支持类型",
    "system.channel.withdraw": "提现",
    "system.channel.trade": "交易",
    "system.channel.trade_methods": "交易方式",
    "system.channel.trade_methods.pay": "代付",
    "system.channel.trade_methods.receive": "代收",
    "system.channel.tripartite": "上游",
    "system.channel.is_tripartite": "是否为上游",
    "system.channel.is_active": "是否开启",
    "system.channel.ext_infos": "上游信息",
    "system.channel.fields": "渠道信息",
    "system.channel.review": "审核信息",
    "system.channel.name.placeholder": "请填写渠道名称",
    "system.channel.logo.placeholder": "请上传渠道Logo",
    "system.withdraw_channel": "提现渠道管理",
    "system.account": "卡号管理",
    "system.account.id": "ID",
    "system.account.channel": "卡类型",
    "system.account.fees": "佣金",
    "system.account.amount": "额度",
    "system.account.min": "最小收款",
    "system.account.channel.placeholder": "请选择卡类型",
    "system.account.amount.placeholder": "请填写卡额度",
    "system.account.min.placeholder": "请填写最小收款额度",
    "system.account.info": "卡信息",
    "system.account.active": "激活",
    "system.account.edit": "修改额度",
    "system.account.creator": "创建者",
    "system.logs": "操作日志",
    "system.logs.id": "日志ID",
    "system.logs.desc": "操作说明",
    "system.logs.admin": "操作人",
    "transaction.management": "订单管理",
    "transaction.orders": "订单列表",
    "transaction.orders.pay": "代付订单",
    "transaction.orders.receive": "代收订单",
    "transaction.orders.trade_no": "交易号",
    "transaction.orders.trade_type": "交易类型",
    "transaction.orders.trade_type.receive": "代收",
    "transaction.orders.trade_type.pay": "代付",
    "transaction.orders.account": "账号",
    "transaction.orders.channel": "产品",
    "transaction.orders.amount": "订单金额",
    "transaction.orders.amount_received": "实际金额",
    "transaction.orders.fees": "手续费",
    "transaction.orders.extra_fees": "单笔收费",
    "transaction.orders.fees.desc": "订单完成后，会根据",
    "transaction.orders.app": "应用",
    "transaction.orders.status": "状态",
    "transaction.orders.status.0": "待提交",
    "transaction.orders.status.1": "已完成",
    "transaction.orders.status.2": "已取消",
    "transaction.orders.status.3": "待审核",
    "transaction.orders.status.4": "驳回",
    "transaction.orders.create_at": "交易时间",
    "transaction.orders.review": "调整订单",
    "transaction.orders.trade_no.placeholder": "请填写交易号",
    'financial.management': "财务管理",
    'financial.apps': "财务统计",
    'financial.amount': "金额",
    'financial.freeze': "冻结金额",
    'financial.withdraw': '提现记录',
    'financial.subject': '商家筛选',
    "financial.withdraw.submit": "提现",
    "financial.withdraw.amount": "提现金额",
    "financial.withdraw.placeholder": "请输入提现金额",
    "financial.withdraw.max": "最大提现金额：",
    "financial.withdraw.google": "谷歌验证码",
    "financial.withdraw.google.placeholder": "请填写谷歌验证码",
    "financial.withdraw.success": "提现提交成功，等待客户审核",
    'financial.withdraw.id': "记录ID",
    'financial.withdraw.app': "归属App",
    'financial.withdraw.channel': "提现渠道",
    'financial.withdraw.info': "提现信息",
    'financial.withdraw.create_at': "提现时间",
    'financial.withdraw.status': "状态",
    'financial.withdraw.status.0': "待审核",
    'financial.withdraw.status.1': "已成功",
    'financial.withdraw.status.2': "已拒绝",
    'financial.withdraw.status.3': "已取消",
    'financial.withdraw.update': "审核",
    'financial.withdraw.status_desc': "描述",
    'financial.withdraw.status_desc.placeholder': "请填写描述信息",
    'financial.withdraw.exchange_rate.edit': "调整",
    'financial.withdraw.exchange_rate': "提现汇率",
    'financial.withdraw.amount_received': "实际到账",
    'financial.flows': '资金流水',
    'financial.flows.id': '流水编号',
    'financial.flows.type': '流水类型',
    'financial.flows.type.credit': '进账',
    'financial.flows.type.billing': '出账',
    'financial.flows.type.adjustment': '调整',
    'financial.flows.type.freeze': '冻结',
    'financial.flows.type.withdraw': '提现',
    'financial.flows.amount': '操作金额',
    'financial.flows.balance': '账户余额',
    'financial.flows.freeze': '冻结余额',
    'financial.flows.operator': '操作人',
    'financial.flows.description': '说明',
    'financial.flows.created_at': '创建时间',
    "api.doc": "接口文档",
    "setting.management": "配置",
    'setting.google': '谷歌验证器',
    'setting.google.alert': '您尚未绑定谷歌验证器，请先绑定',
    'setting.google.go': '前往绑定',
    'setting.google.secret': 'Secret',
    'setting.google.qrcode': '二维码',
    'setting.google.verify_code': '验证码',
    'setting.google.verify_code.error': '请填写验证码',
    'setting.google.binded': '已绑定谷歌验证器',
    'setting.google.binded.desc': '若需更换，请联系管理员',
    "setting.google.unbind": "解绑谷歌验证器",
    "setting.google.unbind.content": "是否为该用户解绑谷歌验证器",
    "setting.api_test": "接口调试",
    "setting.api_test.interface": "接口",
    "setting.api_test.interface.placeholder": "请选择要调试的接口",
    "setting.api_test.field.placeholder": "字段不能为空"
  }
};
export default i18n;