import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
export const routes = [{
  name: 'menu.dashboard',
  key: 'dashboard',
  children: [{
    name: 'menu.dashboard.workplace',
    key: 'dashboard/workplace'
  }]
}, {
  name: 'account.management',
  key: 'account.management',
  children: [{
    name: 'account.admin',
    key: 'account/admin',
    requiredPermissions: [{
      resource: '/api/admin',
      actions: ['read']
    }]
  }, {
    name: 'account.role',
    key: 'account/role',
    requiredPermissions: [{
      resource: '/api/role',
      actions: ['read']
    }]
  }]
}, {
  name: 'transaction.management',
  key: 'transaction.management',
  children: [{
    name: 'transaction.orders.receive',
    key: 'transaction/receive',
    requiredPermissions: [{
      resource: '/api/transaction',
      actions: ['read']
    }]
  }, {
    name: 'transaction.orders.pay',
    key: 'transaction/pay',
    requiredPermissions: [{
      resource: '/api/transaction',
      actions: ['read']
    }]
  }]
}, {
  name: 'merchant.management',
  key: 'merchant.management',
  children: [{
    name: 'merchant.list',
    key: 'merchant/list',
    requiredPermissions: [{
      resource: '/api/subject',
      actions: ['read']
    }]
  }, {
    name: 'merchant.apps',
    key: 'merchant/apps',
    requiredPermissions: [{
      resource: '/api/app',
      actions: ['read']
    }]
  }]
}, {
  name: 'financial.management',
  key: 'financial.management',
  children: [{
    name: 'financial.apps',
    key: 'financial/apps',
    requiredPermissions: [{
      resource: '/api/dashboard/financial',
      actions: ['read']
    }]
  }, {
    name: 'financial.withdraw',
    key: 'financial/withdraw',
    requiredPermissions: [{
      resource: '/api/withdraw',
      actions: ['read']
    }]
  }, {
    name: 'financial.flows',
    key: 'financial/flows',
    requiredPermissions: [{
      resource: '/api/flows',
      actions: ['read']
    }]
  }]
}, {
  name: 'system.management',
  key: 'system.management',
  children: [{
    name: 'system.country',
    key: 'system/country',
    requiredPermissions: [{
      resource: '/api/country',
      actions: ['read']
    }]
  }, {
    name: 'system.channel',
    key: 'system/channel',
    requiredPermissions: [{
      resource: '/api/channel',
      actions: ['read']
    }]
  }, {
    name: 'system.withdraw_channel',
    key: 'system/withdraw_channel',
    requiredPermissions: [{
      resource: '/api/withdraw_channel',
      actions: ['read']
    }]
  }, {
    name: 'system.account',
    key: 'system/account',
    requiredPermissions: [{
      resource: '/api/account',
      actions: ['read']
    }]
  }, {
    name: 'system.logs',
    key: 'system/logs',
    requiredPermissions: [{
      resource: '/api/logs',
      actions: ['read']
    }]
  }]
}, {
  name: 'setting.management',
  key: 'setting.management',
  children: [{
    name: 'setting.google',
    key: 'setting/google'
  }, {
    name: 'setting.api_test',
    key: 'setting/api_test'
  }]
}, {
  name: 'api.doc',
  key: 'api_doc',
  breadcrumb: true,
  ignore: true
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.key] = actions;
      });
    }
  });
  return result;
};
const useRoute = userPermission => {
  const {
    userInfo
  } = useSelector(state => state);
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions && userInfo.role !== 'admin') {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};
export default useRoute;