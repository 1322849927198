import defaultSettings from '../settings.json';
const initialState = {
  settings: defaultSettings,
  userInfo: {
    permissions: {}
  }
};
const PERMISSION_ACTION_MAPPING = {
  "POST": "write",
  "GET": "read",
  "PUT": "edit",
  "DELETE": "delete"
};
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return {
          ...state,
          settings
        };
      }
    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        // 对接口权限处理
        if (Array.isArray(userInfo.permissions)) {
          const permissions = {};
          userInfo.permissions.forEach(permission => {
            const [action, resource] = permission.split(' ');
            if (resource in permissions) {
              permissions[resource].push(PERMISSION_ACTION_MAPPING[action]);
            } else {
              permissions[resource] = [PERMISSION_ACTION_MAPPING[action]];
            }
          });
          userInfo.permissions = permissions;
        }
        return {
          ...state,
          userLoading,
          userInfo
        };
      }
    default:
      return state;
  }
}